import React from "react"
import SEO from "../components/seo"
import Header from "../components/header"
import Footer from "../components/footer"
import { Link } from "gatsby"

// Styles
import styles from "../styles/pages/success.module.scss"

const ThankYouPage = () => (
  <>
    <Header />
    <SEO title="Success" />
    <div className={styles.main}>
      <div className={styles.content}>
        <h1>Thank You For Leaving A Review!</h1>
        <p>
          Your comments and opinions help us provide a better service. Thank
          You!
        </p>
        <Link>
          <button className={styles.button}>Go Back</button>
        </Link>
      </div>
    </div>
    <Footer />
  </>
)

export default ThankYouPage
